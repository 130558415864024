.navbar {
    height: 10vh;
    width: auto !important;
    max-width: 100% !important;
    padding: 2vh 10px 3vh 10px;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navContainer {
    padding: 0px 70px !important;
    display: flex;
    justify-content: flex-start;
    width: 71%;
    margin-top: 12px;
}

.navLink {
    display: flex;
    align-items: center;
    font-size: 11px;
    padding-bottom: 2vh;
    margin-left: 20px;
    color: #7a7a7a;
    text-decoration: none;
    border-bottom: 4px solid rgba(85, 158, 255, 0)
}

.selected {
    color: #0075B2;
}

.navTitle {
    margin-left: 5px;
}

.navLink:hover {
    text-decoration: none;
    color: #0075B2 !important;
    border-bottom: 4px solid rgba(85, 158, 255, 1) !important;
}

.rightNav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
}