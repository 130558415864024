.sort-header {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.sort-header:hover {
    color: gray !important;
}

.sort-header:hover>svg {
    color: gray !important;
}