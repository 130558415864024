@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif !important;
}

body::-webkit-scrollbar {
    display: none
}

.lightTheme {
    color: #5A5A5A;
    background-color: white;
}

.darkTheme {
    color: #E4E3E3;
    background-color: #232D3B;
}

.lightPage {
    color: #5A5A5A;
    background-color: #eaecee;
}

.darkPage {
    color: #E4E3E3;
    background-color: #1A222D;
}

.navPage {
    min-height: 80vh;
}

fieldset {
    border: none !important;
}

.hand-symbol {
    cursor: pointer;
}

input::placeholder {
    color: rgb(182, 182, 182) !important;
}

.custom-scrollbar-light::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #D9D9D9;
}

.custom-scrollbar-light::-webkit-scrollbar-corner {
    background-color: #D9D9D9;
}

.custom-scrollbar-light::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #D9D9D9 ;
}

.custom-scrollbar-light::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #2A3545;
}

.custom-scrollbar-dark::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #2A3545;
}

.custom-scrollbar-dark::-webkit-scrollbar-corner {
    background-color: #2A3545;
}

.custom-scrollbar-dark::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #2A3545;
}

.custom-scrollbar-dark::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #D9D9D9;
}

.Mui-disabled {
    -webkit-text-fill-color: gray !important;
}

input[type="time"]::-webkit-calendar-picker-indicator{
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.react-tel-input .country-list .country.highlight {
    color: #1976D2 !important;
}

.react-tel-input .country-list :hover {
    color: #1976D2 !important;
}
.css-ohwg9z{
    height: 100% !important;
}
.css-1l7c0cy{
    height: 100% !important;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.scale-in-hor-left{animation:scale-in-hor-left .6s cubic-bezier(.25,.46,.45,.94) both}
@keyframes scale-in-hor-left{0%{transform:scaleX(0);transform-origin:0 0;opacity:1}100%{transform:scaleX(1);transform-origin:0 0;opacity:1}}

.recharts-text, .recharts-cartesian-axis-tick-value{
    font-size: 9px !important;
}
.recharts-default-legend{
    padding-bottom: 10px !important;
}