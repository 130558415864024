.circleSummary {
  width: 10vw;
  height: 10vw;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circlecountSummary {
  font-size: 15px;
}