.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 9px;
  height: 11px;
  overflow: hidden;
}

.progress {
  height: 100%;
}